function salutationIdToString(salutationId) {
  switch (salutationId) {
    case '100001':
      return 'Herr';
    case '100002':
      return 'Frau';
    case '100007':
      return 'Firma';
    case '100009':
      return 'Eheleute';
  }
}

function salutationEnumToString(salutationEnum) {
  switch (salutationEnum) {
    case 'MISS':
      return 'Frau';
    case 'MISTER':
      return 'Herr';
    case 'COUPLE':
      return 'Eheleute';
    case 'ALL':
      return 'ALL';
  }
}

export default {
  salutationIdToString,
  salutationEnumToString
};
