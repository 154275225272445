<template>
  <div class="group">
    <div>{{ title }}</div>
    <div>
      <div v-if="showCustomerNumber">
        <div class="label">
          Kundennummer
        </div>
        <div v-if="data.customerNumber">
          {{ data.customerNumber }}
        </div>
        <div v-else>
          -
        </div>
      </div>

      <div v-if="!hideName">
        <div class="label">
          Vor-/ Nachname
        </div>
        <div>
          {{ getFormattedSalutation() }} {{ data.firstName }}
          {{ data.lastName }}
        </div>
      </div>

      <div v-if="!hideCompany">
        <div class="label">
          Firma
        </div>
        <div v-if="data.company">
          {{ data.company }}
        </div>
        <div v-else>
          -
        </div>
      </div>

      <div v-if="!hidePhoneNumber">
        <div class="label">
          Telefonnummer
        </div>
        <div v-if="data.phone">
          {{ data.phone }}
        </div>
        <div v-else>
          -
        </div>
      </div>

      <div v-if="!hideMobileNumber">
        <div class="label">
          Handynummer
        </div>
        <div v-if="data.mobile">
          {{ data.mobile }}
        </div>
        <div v-else>
          -
        </div>
      </div>

      <div v-if="!hideBirthday">
        <div class="label">
          Geburtsdatum
        </div>
        <div v-if="data.birthDate">
          {{ new Date(data.birthDate).toLocaleDateString('de-DE') }}
        </div>
        <div v-else>
          -
        </div>
      </div>

      <div v-if="!hideEmail">
        <div class="label">
          E-Mail
        </div>
        <div v-if="data.email" class="space">
          {{ data.email }}
        </div>
        <div v-else>
          -
        </div>
      </div>

      <div>
        <div class="label">
          Adresse
        </div>
        <div>{{ data.street }} {{ data.houseno }}{{ data.housenoSuppl }}</div>
        <div>{{ data.cityCode }} {{ data.city }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// Deprecated, will be replaced by CustomerDetails
import utils from '@/_obsolete/Helper.js';

export default {
  name: 'CustomerData',
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showCustomerNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    hideName: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCompany: {
      type: Boolean,
      required: false,
      default: false
    },
    hidePhoneNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    hideMobileNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    hideBirthday: {
      type: Boolean,
      required: false,
      default: false
    },
    hideEmail: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getFormattedSalutation: function () {
      return utils.salutationEnumToString(this.data.salutation);
    }
  }
};
</script>

<style scoped>
.group > div:nth-child(2) > div {
  margin-bottom: var(--goe-spacing-2);
}

.group > div:nth-child(2) > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}
</style>
