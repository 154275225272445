<template>
  <div class="prozess-wrapper">
    <modal
      name="edit-customer-data-modal"
      classes="modal-with-padding"
      width="80%"
      height="80%"
      :maxWidth="1000"
      :maxHeight="800"
      :adaptive="true"
    >
      <h2 class="title">
        <div>Kundendaten bearbeiten</div>
      </h2>
      <IncomingServiceOrderCustomerData
        :data="customerData"
        @onSubmit="changeCustomerData"
      />
    </modal>

    <div class="prozess">
      <div>
        <div class="prozess-step">
          Kundendaten überprüfen
        </div>

        <div v-if="showAfterEditHint && !isLoading" class="red-hint">
          Gegebenenfalls vorgenommene Änderungen sind nicht gespeichert und
          gehen beim verlassen verloren!
        </div>

        <div v-if="customerData !== null && isLoading === false">
          <CustomerData
            :data="customerData.contactAddress"
            title="Kundeninformationen"
          />
          <!--
          <PaymentData :data="customerData.sepa" /> -->

          <CustomerData
            :data="customerData.invoiceAddress"
            title="Rechnungsinformationen"
            hidePhoneNumber
            hideMobileNumber
            hideBirthday
            hideEmail
          />

          <CustomerData
            v-if="
              customerData.installationAddress !== null &&
                customerData.installationAddress !== undefined
            "
            :data="customerData.invoiceAddress"
            title="Installationsinformationen"
            hideName
            hidePhoneNumber
            hideMobileNumber
            hideBirthday
            hideEmail
          />

          <div class="group">
            <div>Produkte</div>
            <div>
              <div
                v-for="id in productList"
                :key="id"
                class="product-item item"
              >
                <span>{{ mapProductIdToValue(id) }}</span>
              </div>
            </div>
          </div>
        </div>

        <Loader v-if="isLoading" />
      </div>

      <div v-if="!isLoading" class="buttons-wrapper">
        <NormalButton
          description="Bearbeiten"
          buttonStyle="clear"
          @onClick="$modal.show('edit-customer-data-modal')"
        />
        <NormalButton
          description="Verifizieren"
          @onClick="verifyCustomerData"
        />
      </div>
    </div>

    <TaskBlocker :assignee="data.assignee" />
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import utils from '@/_obsolete/Helper.js';
import NotificationObject from '@/main/NotificationObject.js';
import products from '@/_deprecated/Products.js';
import mapCustomerDataToDto from '@/_obsolete/ToDtoMapper.js';
import IncomingServiceOrderCustomerData from '@/components/orderEntry/orderCreation/CustomerDataSelection.vue';

import TaskBlocker from '@/task/TaskBlocker.vue';
import CustomerData from '@/_obsolete/CustomerData.vue';
// import PaymentData from '@/processLaunch/orderEntry/PaymentData.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';

export default {
  name: 'ActionCustomerData',
  components: {
    NormalButton,
    TaskBlocker,
    CustomerData,
    // PaymentData,
    Loader,
    IncomingServiceOrderCustomerData
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    showAfterEditHint: false,
    initCustomerData: null,
    customerData: null,
    productList: []
  }),
  watch: {
    data: function (value, _) {
      this.loadCustomerData(value.id);
    }
  },
  mounted: function () {
    this.loadCustomerData(this.$props.data.id);
  },
  methods: {
    getFormattedSalutation: function () {
      return utils.salutationIdToString(
        this.customerData.contactAddress.salutation
      );
    },
    mapProductIdToValue: function (productId) {
      var productData = products.find((item) => item.id === productId);
      return productData !== undefined ? productData.name : '';
    },
    changeCustomerData: function (data) {
      const nCustomerData = mapCustomerDataToDto(data).customer;

      this.showAfterEditHint =
        JSON.stringify(this.initCustomerData) !== JSON.stringify(nCustomerData);
      this.$modal.hide('edit-customer-data-modal');
      this.customerData = nCustomerData;
    },
    loadCustomerData: function (id) {
      this.isLoading = true;
      HTTP.get(
        `/engine/default/task/${id}/form-variables?deserializeValues=false`
      )
        .then((resp) => {
          var data = JSON.parse(resp.data.order.value);
          this.initCustomerData = data.customer;
          this.customerData = data.customer;
          this.productList = data.products;
        })
        .catch(() =>
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'error',
              'Die Kundendaten konnten nicht geladen werden!'
            )
          )
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    verifyCustomerData: function () {
      this.isLoading = true;
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        variables: {
          order: {
            type: 'Object',
            value: JSON.stringify({
              customer: this.customerData,
              products: this.productList
            }),
            valueInfo: {
              objectTypeName: 'OrderDto',
              serializationDataFormat: 'application/json'
            }
          }
        }
      })
        .then((resp) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'info',
              'Die Kundendaten wurden erfolgreich verifiziert!'
            )
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'error',
              'Die Kundendaten konnten nicht erfolgreich verifiziert werden!'
            )
          )
        )
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.loader {
  margin: auto;
}
</style>
