<template>
  <div class="process-viewer interaction-wrapper">
    <h2 class="title">
      <div>Prozessansicht</div>
      <div v-if="!isModal" class="title-actions">
        <GoetelButton
          v-if="
            src === 'Kundenanschaltung_Eingang_Diensteauftrag.bpmn' ||
              src === 'Kundenanschaltung_Provisionierung_RufnummerPortierung.bpmn'
          "
          description="Prozess erstellen"
          customClass="small"
          @onClick="$emit('onCreate')"
        />
        <ImageButton
          :src="`${publicPath}close.png`"
          buttonType="clear"
          @onClick="$emit('onClose')"
        />
      </div>
    </h2>

    <div class="viewer">
      <vue-bpmn :url="`${publicPath}${src}`" @error="handleError" />
    </div>
  </div>
</template>

<script>
import VueBpmn from 'vue-bpmn';

import ImageButton from '@/components/elements/ImageButton.vue';
import GoetelButton from '@/components/elements/GoetelButton.vue';

import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'Processviewer',
  components: { ImageButton, GoetelButton, VueBpmn },
  props: {
    src: {
      type: String,
      required: true
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL
  }),
  methods: {
    handleError: function () {
      this.$store.commit(
        'addNotification',
        new NotificationObject(
          'error',
          'Der Prozess konnte nicht erfolgreich geladen werden!'
        )
      );
    }
  }
};
</script>

<style scoped>
h2 {
  display: grid;
  grid-template-columns: auto max-content;
}

.title-actions {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: var(--goe-spacing-2);
}

.actions {
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

.viewer {
  margin: var(--goe-spacing-2);
  user-select: none;
  pointer-events: none;
}

.process-viewer {
  display: grid;
  grid-template-rows: max-content auto;
}

.vue-bpmn-diagram-container {
  height: 100%;
  width: 100%;
}
</style>
