const products = [
  {
    id: 100289,
    name: 'LW10',
    price: '24,95€ / Mon.',
    type: 'base',
    description: [
      'Internet-Flat',
      '10 Mbit/s Downstream',
      '2 Mbit/s Upstream',
      'Inklusive 3 Rufnummer',
      '2 Sprachkanäle',
      'Telefonflat ins deutsche Festnet'
    ]
  },
  {
    id: 100290,
    name: 'LW50',
    price: '39,95€ / Mon.',
    type: 'base',
    description: [
      'Internet-Flat',
      '50 Mbit/s Downstream',
      '5 Mbit/s Upstream',
      'Inklusive 3 Rufnummer',
      '2 Sprachkanäle',
      'Telefonflat ins deutsche Festnet'
    ]
  },
  {
    id: 100287,
    name: 'LW200',
    price: '49,95€ / Mon.',
    type: 'base',
    description: [
      'Internet-Flat',
      '200 Mbit/s Downstream',
      '10 Mbit/s Upstream',
      'Inklusive 3 Rufnummer',
      '2 Sprachkanäle',
      'Telefonflat ins deutsche Festnet'
    ]
  },
  {
    id: 100358,
    name: 'LW500',
    price: '59,95€ / Mon.',
    type: 'base',
    description: [
      'Internet-Flat',
      '500 Mbit/s Downstream',
      '25 Mbit/s Upstream',
      'Inklusive 3 Rufnummer',
      '2 Sprachkanäle',
      'Telefonflat ins deutsche Festnet'
    ]
  },
  {
    id: 100291,
    name: 'LW1000',
    price: '79,95€ / Mon.',
    type: 'base',
    description: [
      'Internet-Flat',
      '1.000 Mbit/s Downstream',
      '50 Mbit/s Upstream',
      'Inklusive 3 Rufnummer',
      '2 Sprachkanäle',
      'Telefonflat ins deutsche Festnet'
    ]
  },
  {
    id: 100303,
    name: 'Telefon PUR',
    price: '-4,95€ / Mon.',
    type: 'optional',
    require: 100289,
    optionalRules: {
      disabledBy: 100304
    }
  },
  {
    id: 100304,
    name: 'Internet PUR',
    price: '-4,95€ / Mon.',
    type: 'optional',
    optionalRules: {
      disabledBy: 100303
    }
  },
  {
    id: 100311,
    name: 'Mobil-Option Flat',
    price: '14,95€ / Mon.',
    type: 'optional',
    description: ['Kostenlose Gespräche in alle deutsche Mobilfunknetze']
  },
  {
    id: 100305,
    name: 'Uploadturbo',
    price: '4,95€ / Mon.',
    type: 'optional',
    description: ['Erhöhung des Upstreams auf Hälfte des Downstreams'],
    require: 100289
  },
  {
    id: 100306,
    name: 'Uploadturbo',
    price: '4,95€ / Mon.',
    type: 'optional',
    description: ['Erhöhung des Upstreams auf Hälfte des Downstreams'],
    require: 100290
  },
  {
    id: 100307,
    name: 'Uploadturbo',
    price: '4,95€ / Mon.',
    type: 'optional',
    description: ['Erhöhung des Upstreams auf Hälfte des Downstreams'],
    require: 100287
  },
  {
    id: 100359,
    name: 'Uploadturbo',
    price: '4,95€ / Mon.',
    type: 'optional',
    description: ['Erhöhung des Upstreams auf Hälfte des Downstreams'],
    require: 100358
  },
  {
    id: 100308,
    name: 'Uploadturbo',
    price: '4,95€ / Mon.',
    type: 'optional',
    description: ['Erhöhung des Upstreams auf Hälfte des Downstreams'],
    require: 100291
  },
  {
    id: 100309,
    name: 'FRITZ!Box-Option',
    price: '4,95€ / Mon.',
    type: 'optional',
    description: [
      'FRITZ!Box 7590',
      'Bis zu 10 Rufnummern',
      'S°-Anschluss für ISDN-Geräte'
    ]
  },
  {
    id: 100310,
    name: 'FRITZ!Box-Installation',
    price: '79,95 / einm.',
    type: 'optional',
    description: [
      'FRITZ!Box wird vorkonfiguriert',
      'Anschluss beim Kunden (ohne Kabelmontage)',
      'Einrichtung vor Ort'
    ],
    optionalRules: {
      enabledBy: 100309
    }
  }
]

export default products
