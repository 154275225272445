export default function mapCustomerDataToDto(data) {
  const dto = {
    customer: {
      contactAddress: {
        company: null,
        fax: '',
        firstName: data.firstname,
        lastName: data.lastname,
        street: data.street,
        birthDate:
          typeof data.birthday === 'string'
            ? data.birthday
            : data.birthday.toISOString(),
        houseno: data.houseNo.replaceAll(/[a-zA-Z]/g, ''),
        housenoSuppl: data.houseNo.replaceAll(/[0-9]/g, ''),
        cityCode: data.cityCode,
        city: data.city,
        salutation: data.salutation,
        email: data.email,
        phone: data.phoneNumber,
        phone2: data.mobileNumber
      },
      sepa: {
        iban: data.iban,
        bic: data.bic
      }
    }
  }

  if (data.bStreet !== undefined && data.bStreet !== null) {
    dto.customer.invoiceAddress = {
      ...dto.customer.contactAddress,
      company: data.salutation === '100007' ? data.bLastnameCompany : null,
      firstName: data.bFirstnameCompanyAdditional,
      lastName: data.bLastnameCompany,
      street: data.bStreet,
      houseno: data.bHouseNo.replaceAll(/[a-zA-Z]/g, ''),
      housenoSuppl: data.bHouseNo.replaceAll(/[0-9]/g, ''),
      cityCode: data.bCityCode,
      city: data.bCity
    }
  } else {
    dto.customer.invoiceAddress = { ...dto.customer.contactAddress }
  }

  if (data.dStreet !== undefined && data.dStreet !== null) {
    dto.customer.installationAddress = {
      ...dto.customer.contactAddress,
      street: data.dStreet,
      houseno: data.dHouseNo.replaceAll(/[a-zA-Z]/g, ''),
      housenoSuppl: data.dHouseNo.replaceAll(/[0-9]/g, ''),
      cityCode: data.dCityCode,
      city: data.dCity
    }
  }

  return dto
}
